import { isNotNullish, isNullish } from '@examdojo/util/nullish';

export class LocalStorageManager<T extends object> {
  constructor(private readonly key: string) {}

  getValue(): T | null {
    const val = localStorage.getItem(this.key) as string | null;
    if (isNullish(val)) {
      return null;
    }
    try {
      return JSON.parse(val);
    } catch (_error) {
      return null;
    }
  }

  setValue(val: T) {
    localStorage.setItem(this.key, JSON.stringify(val));
  }
}

/**
 * @deprecated Use LocalStorageManager instead
 */
export const createLSValueMaintainer = <T>(namespace: string, scopeKey: string) => {
  return {
    getValue: (fallbackValue: T): T => {
      const val = localStorage.getItem(namespace) as string | null;
      if (val) {
        try {
          const parsedMap = JSON.parse(val);
          const payload = parsedMap[scopeKey] as T;
          if (isNotNullish(payload)) {
            return payload;
          }
        } catch (__err) {
          // do nothing and go to fallback
        }
      }

      localStorage.removeItem(namespace);
      return fallbackValue;
    },
    setValue: (val: T | null) => {
      if (isNotNullish(val)) {
        localStorage.setItem(namespace, JSON.stringify({ [scopeKey]: val }));
      } else {
        localStorage.removeItem(namespace);
      }
    },
  };
};
